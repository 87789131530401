import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SEO } from 'src/components/SEO';
import { Layout } from 'src/components/Layout';
import { PageTitle } from 'src/components/PageTitle';
import { BlueBox } from 'src/components/BlueBox';

const Contractors = () => (
  <Layout className="recruitment">
    <SEO title="Contractors" />
    <div className="container pt-2">
      <PageTitle className="mb-2 mb-lg-8" title='Contractors' />
      <h2 className="mb-3">How do you know you need contractors?</h2>
      <div className="row mb-lg-4">
        <div className="col-6 col-lg-4 col-xl-2">
          <StaticImage
            src="../assets/images/recruitment/showing.png"
            alt="Graphic"
            layout="fullWidth"
          />
        </div>
        <BlueBox text={<span>You need to get the job done <strong>now</strong></span>} />
        <BlueBox text={<span>No available headcounts but <strong>loads of work</strong></span>} />
        <BlueBox text={<span><strong>Clear plan</strong> but missing team to execute it</span>} />
        <BlueBox text={<span><strong>Tight timeline</strong></span>} />
        <BlueBox text={<span>Facing <strong>cost of delay</strong></span>} />
      </div>
    </div>
    <div className="container pb-2 pb-lg-6">
      <div className="white-box p-lg-4">
        <h2 className="mb-1 mb-lg-3">You have the plan and we help you to execute it!</h2>
        <div className="row">
          <div className="col-12 col-lg-6">
            <ul className="white-box__list">
              <li>We understand the project and who you are looking for</li>
              <li>With our experience we will only recommend the best fit contractors with industry validated skills and expertise</li>
              <li>Very straightforward cost management</li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 pl-lg-8">
            <StaticImage
              className="mt-4 mt-lg-0"
              src="../assets/images/contractors/plan.png"
              alt="Graphic"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
      <div className="white-box p-lg-4">
        <h2 className="mb-1 mb-lg-3">Fast track</h2>
        <div className="row">
          <div className="col-12 col-lg-6">
            <ul className="white-box__list">
              <li>Short-list of the prescreened contractors</li>
              <li>You run only the final interview round</li>
              <li>No career plans required</li>
              <li>Hiring completed in less than 20 days</li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 pl-lg-8">
            <StaticImage
              className="mt-4 mt-lg-0"
              src="../assets/images/contractors/track.png"
              alt="Graphic"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
      <div className="white-box p-lg-4">
        <h2 className="mb-1 mb-lg-3">Perfect match</h2>
        <div className="row">
          <div className="col-12 col-lg-6">
            <ul className="white-box__list">
              <li>You hire developers and engineers that are the same caliber as your full-time employees</li>
              <li>Immediate engagement of the contractors in the project</li>
              <li>Contractors are integral part of the in-house team</li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 pl-lg-8">
            <StaticImage
              className="mt-4 mt-lg-0"
              src="../assets/images/contractors/match.png"
              alt="Graphic"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contractors;
